import React from "react";
import { PageSections } from "@happy-rabbit/gatsby-sanity-cms";
import { imageFromPageSection } from "../../utils/pages";
import { resolvePageSection } from "../PageSections";
// import { renderPageSection } from "../PageSections";
import SEO from "../SEO";

const PageView = props => {
  const { page } = props;

  const pageSectionImage = imageFromPageSection(page.pageSections[0]);

  return (
    <>
      <SEO
        pageFields={{
          image: pageSectionImage,
        }}
      />

      <PageSections page={page} pageSections={page.pageSections} resolvePageSection={resolvePageSection} />
      {/* <div className="">
        {page.pageSections.map(renderPageSection)}
      </div> */}
    </>
  );
}

export default PageView;
